import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import {
  selectGroupStatus,
  selectIsGroupSecret,
  selectIsJoinedGroupMember,
  selectIsPendingGroupMember,
} from 'store/selectors';

import { useController } from 'common/context/controller';

import { Button, type IButtonProps } from 'wui/Button';

import { selectButtonLabel } from './selectors';

export interface MembershipButtonProps extends IButtonProps {
  bi?: any;
  redirect?: boolean;
  groupId: string;
  children?: string;

  onClick?(event: React.MouseEvent): void;
}

export function GroupMembershipButton(props: MembershipButtonProps) {
  const { groupId, onClick, redirect, ...rest } = props;
  const { t } = useTranslation();
  const bi = useBi();
  const { params } = useCurrentStateAndParams();

  const { isMobile } = useEnvironment();

  const { application$, group$ } = useController();

  const label = useSelector(selectButtonLabel(groupId, isMobile));
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const isPending = useSelector(selectIsPendingGroupMember(groupId));
  const status = useSelector(selectGroupStatus(groupId));
  const isSecret = useSelector(selectIsGroupSecret(groupId));

  const isLoading = status.membership.updating || status.requirements.loading;

  if (isJoined && !props.children) {
    return null;
  }

  if (isSecret && !isJoined && !params.autoInviteId) {
    return null;
  }

  return (
    <Button
      disabled={isLoading}
      onClick={handleClick}
      loading={isLoading}
      outlined={isPending}
      data-is-pending={isPending}
      {...rest}
    >
      {t(props.children || label)}
    </Button>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const hasCustomHandler = !!onClick;

    if (hasCustomHandler) {
      onClick?.(event);
      return;
    }

    if (isPending) {
      application$.showDialog({
        dialog: 'cancelJoinRequest',
        params: { groupId },
      });

      return;
    }

    group$.join({
      groupId,
      redirect,
      autoInviteId: params.autoInviteId,
    });

    if (props.bi) {
      bi.report(props.bi);
    }
  }
}

GroupMembershipButton.displayName = 'GroupMembershipButton';
